@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
$spinner-width: 15px;
$spinner-height: 15px;
$spinner-border-width: 3px;
.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  border: $spinner-border-width solid $white;
  border-right-color: transparent;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  &.dark {
    border: $spinner-border-width solid map-get($map: $primary-color, $key:"default" );
    border-right-color: transparent;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}
