/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $border;
  border-radius: 40px;
  scrollbar-width: thin;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(179, 179, 179, 0.7);
}

.form-control {
  display: block;
  width: 100%;
  padding: 2px 0.5rem 0px 0.5rem;
  font: 400 1rem / 1.9rem 'Open Sans';
  line-height: 1.375rem;
  color: #4f4f4f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;

  &:hover,
  &:focus {
    outline: black solid 0px;
  }
}

input.form-control {
  padding-bottom: 0.3125rem;

  &:disabled {
    color: rgba(0, 0, 0, 0.38);
  }
}

a {
  @apply ap-underline ap-text-blue-link ap-typography-caption;
}

a.no-link-styling {
  all: unset;
}

ap-markdown {
  @apply ap-typography-body-1;

  a {
    @apply ap-typography-body-1;
  }
}

.mat-mdc-tab-body-content {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
}

.dark-spinner {
  border-color: $title;
  border-right-color: transparent;
}

ap-markdown {
  ul,
  ol {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    padding-left: 0;
    list-style-type: auto !important;
    /* for unordered lists */
  }

  li {
    margin: 0;
    padding: 0;
    list-style: inherit;
  }
}

.ap-markdown {
  ul,
  ol {
    padding-left: 0;
    list-style-type: auto !important;
    /* for unordered lists */
  }

  li {
    margin: 0;
    padding: 0;
    list-style: inherit;
  }
}

.thin-scrollbars {
  // height: calc(100vh - 200px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: $border;
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 40px;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(179, 179, 179, 0.7);
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 40px;
    scrollbar-width: thin;
  }
}

.ap-clamp-4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.ap-line-clamp-1 {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.thin-scrollbars.black-scrollbars {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: $body;
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 40px;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $body;
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 40px;
    scrollbar-width: thin;
  }

  mat-tab-header {
    border-bottom-color: $outline;
    border-style: solid;
    border-bottom-width: 1px;

    .mat-mdc-tab-labels {
      padding-left: 0.75rem;
    }

    .mdc-tab {
      fill: var(--mat-tab-header-inactive-label-text-color);
    }

    .mdc-tab--active {
      fill: var(--mat-tab-header-active-label-text-color);
    }
  }
}
.disabled-section {
  pointer-events: none;
  opacity: 0.8;
  background-color: #ffffff;
}
