@function modify-background(
  $theme,
  $background-color,
  $dialog-and-sidenav-background,
  $dividers,
  $text,
  $secondary-text
) {
  // A theme is a map with the following structure:
  // (
  //   color: (..., background),
  //   ...,
  //   background: ...
  // )
  //
  // We need to replace the background color in two palettes.
  // Once in the background palette inside the color map.
  // Again in the background palette itself.

  // Replace the background in the color object's background palette.
  $theme-color: map-get($theme, color);
  $color-background-palette: map-get($theme-color, background);
  $color-background-palette: map-merge(
    $color-background-palette,
    (
      background: $background-color,
      dialog: $dialog-and-sidenav-background,
    )
  );

  // Replace the background in the background palette.
  $background-palette: map-get($theme, background);
  $background-palette: map-merge(
    $background-palette,
    (
      background: $background-color,
      dialog: $dialog-and-sidenav-background,
    )
  );
  $foreground-palette: map-get($theme-color, foreground);
  $foreground-palette: map-merge(
    $foreground-palette,
    (
      dividers: $dividers,
      text: $text,
      secondary-text: $secondary-text,
    )
  );
  $modified-theme-color: map-merge(
    $theme-color,
    (
      background: $color-background-palette,
      foreground: $foreground-palette,
    )
  );

  $modified-theme: map-merge(
    $theme,
    (
      color: $modified-theme-color,
    )
  );
  $modified-theme: map-merge(
    $modified-theme,
    (
      background: $background-palette,
    )
  );

  @return $modified-theme;
}

.mdc-dialog .mdc-dialog__content {
  padding: 3px 24px 20px 24px !important;
  &.ap-p-0
  {
    padding:0px !important
  }
}



.folder-list-item
{
  .material-icons {
    width: 16px !important;
  }
}
