.code .editor-container {
  height: 300px;
}

#context .CodeMirror {
  min-height: 300px !important;
}

code-height-auto .CodeMirror {
  min-height: 100% !important;
}

.code-font-sm .CodeMirror {
  font-size: 14px !important;
}


.code-size-200 .CodeMirror {
  min-height: 100px !important;
  max-height: 100% !important;
}

.CodeMirror {
  min-height: 500px;
  border-radius: 5px;

  ::-webkit-scrollbar {
    width: 5px;
  }
}

.CodeMirror-hscrollbar {
  &::-webkit-scrollbar {
    height: 5px;
  }
}


.code-height-auto .CodeMirror {
  min-height: 100% !important;
}

.code-font-sm .CodeMirror {
  font-size: 14px !important;
}

.console-size .CodeMirror {
  min-height: 100% !important;
}

.code-size-200 .CodeMirror {
  min-height: 100px !important;
  max-height: 100% !important;
}

.CodeMirror {
  min-height: 500px;
  border-radius: 5px;
}

.CodeMirror-hscrollbar {
  &::-webkit-scrollbar {
    height: 5px;
  }
}


.CodeMirror-scroll {
  padding-top: 10px;
}


.modal-fullscreen app-code-editor {
  height: 100%;
}



pre {
  overflow: auto;
}

ngx-monaco-editor {
  overflow: visible;
  .editor-container
  {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
  }
}

.CodeMirror-lint-tooltip {
  z-index: calc($appended-to-body-z-index + 1);
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0px 8px !important;
}

.bar-containing-beautify-button {
  background-color: #193549;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: -3px;
}



.white-nums-column .monaco-editor .margin {
  background-color: var(--white);
}
