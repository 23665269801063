@import "./variables.scss";
@import "./derived-variables.scss";

.add-button {
  position: absolute;
  height: 18px;
  width: 18px;
  z-index: 40;
  background: $add-agent;
  background-image: url("../img/custom/add-button.svg");
  border-radius: 3px;
  transition: box-shadow 0.3s ease-in-out, background 0.3s ease-in-out, background-image 0.3s ease-in-out;

}

.scale-1 {
  transform: scale(1) !important;
}

.drop-point-container {
  position: absolute;
  left: 0px;
  top: -52px;
  z-index: 30;

  &.first-loop-drop {
    left: 210px;
    top: 88px;
  }

  &.empty-loop-drop {
    left: 210px;
    top: 124px;
  }
}

.drop-point-border {
  height: $drop-point-height;
  width: $drop-point-width;
  transform: scale(0);
  // border: 2px $border dashed;
  border-radius: 5px;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  transition-duration: 250ms;
  transition-property: all;
}

.add-button-drop-indicator {
  background: var(--primary-palette-300);
  width: 18px;
  height: 18px;
  border-radius: 3px;
  box-sizing: content-box;
  opacity: 90%;

  &:hover,
  &.show-shadow {
    box-shadow: 0px 0px 0px 6px var(--primary-palette-100);
  }
}