@import '~quill/dist/quill.bubble.css';

.ql-editor {
  overflow: initial;
}
.ql-container {
  border: 0px solid transparent !important;
  .mention-content {
    vertical-align: middle;
    background-color: $gray-card;
    border: 1px solid #9e9e9e;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 3px;
    margin-right: 1.5px;
    margin-left: 1.5px;
    span[contenteditable='false'] {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .mention-logo {
      object-fit: contain;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      margin-bottom: 2px;
      display: inline;
    }
  }
  p {
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0px;
  }

  overflow: initial;
}

.ql-editor.ql-blank::before {
  left: 0px;
  color: #999;
  font-style: initial;
  font-weight: unset;
}

.ql-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
  // ensure quill clipboard preserve whitespaces
  white-space: pre-wrap;
  word-wrap: break-word;
}
.editor-allows-only-one-mention-as-value {
  color: $body;
}
